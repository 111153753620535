import * as React from 'react';

import { ICON_LOOKUP } from './lookup';
type Icon = keyof typeof ICON_LOOKUP;
export type IconProps = React.SVGProps<SVGSVGElement> & {
  family?: 'regular' | 'solid';
  icon: Icon;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
};
type Size = NonNullable<IconProps['size']>;
const SIZES: Record<Size, { width: number | string; height: number | string }> =
  {
    xxs: { height: '1rem', width: '1rem' },
    xs: { height: '1.25rem', width: '1.25rem' },
    sm: { height: '1.5rem', width: '1.5rem' },
    md: { height: 30, width: 30 },
    lg: { height: 40, width: 40 },
  };

function Icon(
  { family = 'solid', icon, size, ...props }: IconProps,
  ref: React.Ref<SVGSVGElement>,
) {
  const Component = ICON_LOOKUP[icon];
  if (!Component) {
    return <>{icon}</>;
  }
  return <Component {...props} {...(size ? SIZES[size] : {})} ref={ref} />;
}

export default React.forwardRef(Icon);
