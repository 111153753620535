import { Button, Card, Col, FullPageSpinner, Row } from '@wyz/ui';
import * as React from 'react';
import { AuthProvider as OidcAuthProvider, useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useConfig, useI18n } from '~/common/context';
import { log } from '~/common/helpers';

function KeyCloackAuthProvider(props: React.PropsWithChildren) {
  const { kcAuthority } = useConfig();
  const config = {
    authority: kcAuthority,
    clientId: 'web',
    redirect_uri: `${location.origin}${location.pathname}`,
  };
  const navigate = useNavigate();
  const oidcConfig = {
    authority: config.authority,
    client_id: config.clientId,
    redirect_uri: config.redirect_uri,
  };
  const handleOnSignin = React.useCallback(() => {
    navigate({
      search: '',
    });
  }, [navigate]);

  return (
    <OidcAuthProvider
      {...oidcConfig}
      onSigninCallback={handleOnSignin}
      stopCheckSessionOnError
      automaticSilentRenew
    >
      {props.children}
    </OidcAuthProvider>
  );
}

function UnAuthenticatedApp() {
  const auth = useAuth();
  const { t } = useI18n();

  const navigate = useNavigate();
  React.useLayoutEffect(() => {
    auth.signinRedirect().catch((error: Error) => {
      log(error);
      navigate('/500');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullPageSpinner>
      <h4>{t('app.common.in_progress_sign_in')}</h4>
    </FullPageSpinner>
  );
}

function ExpiredSession() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useI18n();

  const redirectToSignin = () => {
    auth.signinRedirect().catch((error: Error) => {
      log(error);
      navigate('/500');
    });
  };
  return (
    <Row className={'flex-center min-vh-100 py-6 text-center'}>
      <Col className={'col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4'}>
        <Card className='card border-0 shadow-xxl w-100'>
          <Card.Body className='card-body p-4 p-sm-5 d-flex flex-column'>
            <Card.Title>{t('app.common.session_expired_title')}</Card.Title>
            <p>{t('app.common.session_expired_message')}</p>
            <Button
              id={'btn-signin'}
              variant={'primary'}
              onClick={redirectToSignin}
              size={'lg'}
            >
              {t('app.common.session_expired_button')}
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

function AuthChecker(props: React.PropsWithChildren) {
  const auth = useAuth();
  const { t } = useI18n();

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return (
        <FullPageSpinner>
          <h4>{t('app.common.in_progress_sign_in')}</h4>
        </FullPageSpinner>
      );
    case 'signoutRedirect':
      return (
        <FullPageSpinner>
          <h4>{t('app.common.in_progress_sign_out')}</h4>
        </FullPageSpinner>
      );
    default:
  }
  if (auth.isLoading && auth.activeNavigator !== 'signoutSilent') {
    return <FullPageSpinner />;
  }

  if (auth.isLoading && auth.activeNavigator === 'signoutSilent') {
    return (
      <FullPageSpinner>
        <h4>{t('app.common.in_progress_sign_out')}</h4>
      </FullPageSpinner>
    );
  }

  if (auth.error) {
    // eslint-disable-next-line @wyz/i18n/no-plain-text
    return <ExpiredSession />;
  }
  if (!auth.isAuthenticated && auth.activeNavigator !== 'signoutSilent') {
    return <UnAuthenticatedApp />;
  }
  return <>{props.children}</>;
}

export function AuthProvider(props: React.PropsWithChildren) {
  return (
    <KeyCloackAuthProvider>
      <AuthChecker>{props.children}</AuthChecker>
    </KeyCloackAuthProvider>
  );
}
