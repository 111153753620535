import { IFilter, IPaginationQuery, ITEMS_PER_PAGE } from '@wyz/types';
import { Button, Flex, Form, Icon, RowModel, Text } from '../index';
import { useDatatableContext } from './context';
type Props<TCriteria = IFilter> = {
  onExport?: (criteria: TCriteria) => void;
};
const PaginationControls = <TDataItem extends RowModel, TCriteria = IFilter>({
  onExport,
}: Props<TCriteria>) => {
  const datatableContext = useDatatableContext<TDataItem>();
  const from = datatableContext.internals.query.data?.meta.from ?? 0;
  const to = datatableContext.internals.query.data?.meta.to ?? 0;
  const total = datatableContext.internals.query.data?.meta.total ?? 0;

  function handleItemsPerPageChange(
    itemsPerPage: IPaginationQuery['itemsPerPage'],
  ) {
    datatableContext.internals.setter((prevState) => ({
      ...prevState,
      page: 1,
      itemsPerPage,
    }));
  }

  return (
    <div className='my-2 d-flex align-items-center justify-content-between'>
      <Flex alignItems='center' className='space-x-2'>
        <Text heading='normal' weight='semi-bold'>
          {datatableContext.translations?.pagination?.showingResult
            ? datatableContext.translations?.pagination?.showingResult({
                from,
                to,
                total,
              })
            : `${total} results`}
        </Text>
        {onExport && (
          <Button
            id='export'
            variant='tertiary-blue'
            // disabled={!isEmpty(methods.formState.touchedFields)}
            type='button'
            size='sm'
            onClick={() => {
              const currentFormValue = datatableContext.internals.state
                .transformedCriteria as TCriteria;
              onExport?.(currentFormValue);
            }}
          >
            <span>{datatableContext.translations?.exportBtn ?? 'Export'}</span>
            <Icon icon='upload-line' size='xs' />
          </Button>
        )}
      </Flex>

      <Form.Group className='d-flex align-items-center'>
        <Form.Label className='fs-sm leading-5 opacity-75 mb-0 me-1'>
          {datatableContext.translations?.pagination?.itemsPerPageSelect
            ? datatableContext.translations.pagination.itemsPerPageSelect
            : 'Items/page:'}
        </Form.Label>
        <Form.Select
          onChange={(event) =>
            handleItemsPerPageChange(
              Number(event.target.value) as IPaginationQuery['itemsPerPage'],
            )
          }
          defaultValue={
            datatableContext.internals.state.itemsPerPage ??
            datatableContext.defaults?.itemsPerPage
          }
        >
          {ITEMS_PER_PAGE.map((count) => (
            <option key={count} value={count}>
              {datatableContext.translations?.pagination?.itemsPerPageValue
                ? datatableContext.translations?.pagination?.itemsPerPageValue(
                    count,
                  )
                : count}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default PaginationControls;
