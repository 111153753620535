import * as React from 'react';

interface Props {
  containerStyles?: React.CSSProperties;
}

interface State {
  hasError: boolean;
  error?: undefined | Error;
}

class ErrorBoundary extends React.Component<
  Props & {
    children?: React.ReactNode;
  },
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error(error, 'Datatable', errorInfo);
    this.setState((prev) => ({ ...prev, error }));
    // You can also log the error to an error reporting service
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div style={this.props.containerStyles}>
          <p style={{ position: 'absolute', top: '50%', left: '50%' }}>
            Something went wrong when loading data, please try reloading the
            page
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
